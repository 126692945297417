import { colors } from './theme'

export interface ITagType {
  label: string
  tagColor: string
}
interface ITagTypes {
  [a: string]: ITagType
}
const tagTypes: ITagTypes = {
  books: {
    label: 'books',
    tagColor: colors.BLUE,
  },
  musings: {
    label: 'musings',
    tagColor: colors.MAROON,
  },
  2019: {
    label: '2019',
    tagColor: colors.BLUE,
  },
  readings: {
    label: 'readings',
    tagColor: colors.BLUE,
  },
}

export default tagTypes
