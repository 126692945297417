import { Link as LinkGatsby } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const StyledLink = styled(LinkGatsby)`
  color: ${props => props.color};
  text-decoration: none;

  &:hover {
    color: ${props => props.color};
  }
`

const Link = ({
  to,
  activeStyle = {},
  color = 'black',
  children,
}: {
  to: string
  activeStyle?: object
  color?: string
  children: any
}) => {
  return (
    <StyledLink to={to} activeStyle={activeStyle} color={color}>
      {children}
    </StyledLink>
  )
}

export default Link
