import React from 'react'
import styled from 'styled-components'

import tagTypes, { ITagType } from '../../constants/tagTypes'
import { colors } from '../../constants/theme'
import { IPost } from '../../interfaces'
import Link from '../Link'

const ContainerTitle = styled.span`
  color: ${colors.BLACK};
  font-size: 30px;
`

const ContainerDateAndTimeToRead = styled.div`
  color: ${colors.GREY};
`

const ContainerDate = styled.span``

const ContainerTimeToRead = styled.span`
  margin-left: 40px;
`

const ContainerTags = styled.div`
  color: ${colors.GREY};
  margin-top: 10px;
`

interface IContainerTagProps {
  tagColor: string
}

const ContainerTag = styled.span<IContainerTagProps>`
  color: ${props => props.tagColor};
  border: ${props => `1px solid ${props.tagColor}`};
  border-radius: 25px;
  margin-right: 30px;
  padding: 7px;

  &:hover {
    border: ${props => `2px solid ${props.tagColor}`};
  }
`

const Tag = ({ tag }: { tag: ITagType }) => {
  const { tagColor, label } = tag
  const tagCapitalized = label.charAt(0).toUpperCase() + label.slice(1)
  return (
    <ContainerTag tagColor={tagColor}>
      <Link to={`/tags/${label}`} color={tagColor}>
        {tagCapitalized}
      </Link>
    </ContainerTag>
  )
}

const PostTitle = ({
  isTitleClickable,
  path,
  title,
}: {
  isTitleClickable: boolean
  path: string
  title: string
}) => {
  return isTitleClickable ? (
    <Link to={path} color={colors.BLACK}>
      <ContainerTitle>{title}</ContainerTitle>
    </Link>
  ) : (
    <ContainerTitle>{title}</ContainerTitle>
  )
}

const PostHeader = ({
  post,
  isTitleClickable,
}: {
  post: IPost
  isTitleClickable: boolean
}) => {
  const { frontmatter } = post
  const tags: string[] = frontmatter.tags.split(', ')

  return (
    <>
      <PostTitle
        path={frontmatter.path}
        title={frontmatter.title}
        isTitleClickable={isTitleClickable}
      />
      <ContainerDateAndTimeToRead>
        <ContainerDate>{frontmatter.date}</ContainerDate>
        <ContainerTimeToRead>{`${frontmatter.timeToRead} read`}</ContainerTimeToRead>
      </ContainerDateAndTimeToRead>
      <ContainerTags>
        {tags.map(tag => (
          <Tag key={tagTypes[tag].label} tag={tagTypes[tag]} />
        ))}
      </ContainerTags>
    </>
  )
}

export default PostHeader
