import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout'
import PostHeader from '../components/PostHeader'
import { IPost } from '../interfaces'

const Container = styled.div``
const ContainerPostContent = styled.div`
  margin-top: 30px;
`

interface IData {
  post: IPost
}

function PostTemplate({ data, location }: { data: IData; location: Location }) {
  const { post } = data
  const { html } = post
  return (
    <Layout location={location}>
      <Container>
        <PostHeader post={post} isTitleClickable={false} />
        <ContainerPostContent dangerouslySetInnerHTML={{ __html: html }} />
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
        profile {
          name
          about
          github
          linkedIn
          twitter
        }
      }
    }
    post: markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      excerpt(pruneLength: 250)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        isDraft
        tags
        title
        timeToRead
        path
      }
    }
  }
`

export default PostTemplate
